<template>
  <div class="footer-wrapper mx-auto">
    <v-row no-gutters>
      <v-col class="text-left" cols="2">
        <v-btn text class="text-none font-weight-bold text-caption text--secondary" @click="goToRouteByName('About')" dense>
          Nosotros
        </v-btn>
      </v-col>
      <v-col class="text-right" cols="10">
        <v-btn text class="text-none font-weight-bold text-caption text--secondary" @click="goToRouteByName('Support')" dense>
          Ayuda
        </v-btn>
        <v-btn text class="text-none font-weight-bold text-caption text--secondary" @click="goToRouteByName('Privacy-policies')">
          Privacidad
        </v-btn>
        <v-btn text class="text-none font-weight-bold text-caption text--secondary" @click="goToRouteByName('Terms-and-conditions')">
          Términos
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import navigationMixin from '@/mixins/navigation'

export default {
  name: 'ContentFooter',
  mixins: [navigationMixin]
}
</script>
<style scoped>
.footer-wrapper {
  width: 29.875rem;
  padding-top: 1rem;
}
/* 599px */
@media only screen and (max-width: 37.438rem) {
  .footer-wrapper {
    width: 100%;
    padding: 1rem 0.75rem 0.5rem 0.5rem;
  }
}
</style>
