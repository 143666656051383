<template>
  <v-card flat>
    <div v-if="$vuetify.breakpoint.smAndUp" class="desktop-wrapper">
      <router-view />
      <auth-footer></auth-footer>
    </div>
    <div class="mobile-wrapper" :style="mobileStyles" v-else>
      <div class="mobile-content">
        <router-view />
      </div>
      <auth-footer></auth-footer>
    </div>
  </v-card>
</template>
<script>
import AuthFooter from '@/layouts/Auth/AuthFooter'
import navigationMixin from '@/mixins/navigation'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      mobileStyles: {}
    }
  },
  components: {
    AuthFooter
  },
  methods: {
    calcViewport () {
      this.mobileStyles = {
        minHeight: [window.innerHeight + 'px']
      }
    }
  },
  created () {
    window.addEventListener('resize', this.calcViewport)
  },
  destroyed () {
    window.removeEventListener('resize', this.calcViewport)
  },
  mounted () {
    this.calcViewport()
  }
}
</script>
<style scoped>
.desktop-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.mobile-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.mobile-content {
  flex-grow: 100;
}
</style>
